interface ChannelID {
    [key: string]: Channel;
}

interface Channel {
    country: string,
    icmChannel: string
}

// Update list every time a new channel is rolled out
export const channelList: ChannelID = {
    'pt_BR': {
        country: 'Brazil',
        icmChannel: 'rijkzwaan-brazil-Site/rest;loc=pt_BR;cur=BRL'
    },
    'de_DE': {
        country: 'Germany',
        icmChannel: 'rijkzwaan-germany-Site/rest;loc=de_DE;cur=EUR'
    },
    'de_AT': {
        country: 'Austria',
        icmChannel: 'rijkzwaan-austria-Site/rest;loc=de_AT;cur=EUR'
    },
    'uk_UA': {
        country: 'Ukraine',
        icmChannel: 'rijkzwaan-ukraine-Site/rest;loc=uk_UA;cur=UAH'
    },
    'pl_PL': {
        country: 'Poland',
        icmChannel: 'rijkzwaan-poland-Site/rest;loc=pl_PL;cur=PLN'
    },
    'en_AU': {
        country: 'Australia',
        icmChannel: 'rijkzwaan-australia-Site/rest;loc=en_AU;cur=AUD'
    },
    'en_GB': {
        country: 'United Kingdom',
        icmChannel: 'rijkzwaan-united_kingdom-Site/rest;loc=en_GB;cur=GBP'
    },
    'en_DK': {
        country: 'Scandinavia',
        icmChannel: 'rijkzwaan-scandinavia-Site/rest;loc=en_DK;cur=EUR'
    },
    'nl_BE': {
        country: 'Belgium',
        icmChannel: 'rijkzwaan-belgium-Site/rest;loc=nl_BE;cur=EUR',
    },
    'el_GR': {
        country: 'Greece',
        icmChannel: 'rijkzwaan-greece-Site/rest;loc=el_GR;cur=EUR',
    },
    'hu_HU': {
        country: 'Hungary',
        icmChannel: 'rijkzwaan-hungary-Site/rest;loc=hu_HU;cur=HUF',
    },
    'it_IT': {
        country: 'Italy',
        icmChannel: 'rijkzwaan-italy-Site/rest;loc=it_IT;cur=EUR',
    },
    'fr_FR': {
        country: 'France',
        icmChannel: 'rijkzwaan-france-Site/rest;loc=fr_FR;cur=EUR',
    },
    'pt_PT': {
        country: 'Portugal',
        icmChannel: 'rijkzwaan-portugal-Site/rest;loc=pt_PT;cur=EUR',
    },
    'es_ES': {
        country: 'Spain',
        icmChannel: 'rijkzwaan-spain-Site/rest;loc=es_ES;cur=EUR',
    },
    'es_AR': {
        country: 'Argentina',
        icmChannel: 'rijkzwaan-argentina-Site/rest;loc=es_AR;cur=ARS',
    },
    'es_CL': {
        country: 'Chile',
        icmChannel: 'rijkzwaan-chile-Site/rest;loc=es_CL;cur=CLP',
    },
    'es_GT': {
        country: 'Guatemala',
        icmChannel: 'rijkzwaan-guatemala-Site/rest;loc=es_GT;cur=GTQ',
    },
    'es_MX': {
        country: 'Mexico',
        icmChannel: 'rijkzwaan-mexico-Site/rest;loc=es_MX;cur=MXN',
    },
    'ru_RU': {
        country: 'Russia',
        icmChannel: 'rijkzwaan-russia-Site/rest;loc=ru_RU;cur=RUB',
    },
    'kk_KZ': {
        country: 'Kazakhstan (KK)',
        icmChannel: 'rijkzwaan-kazakhstan-Site/rest;loc=kk_KZ;cur=KZT',
    },
    'ru_KZ': {
        country: 'Kazakhstan (RU)',
        icmChannel: 'rijkzwaan-kazakhstan-Site/rest;loc=ru_KZ;cur=KZT',
    },
    'tr_TR': {
        country: 'Turkey',
        icmChannel: 'rijkzwaan-turkey-Site/rest;loc=tr_TR;cur=TRY',
    },
    'en_ZA': {
        country: 'South Africa',
        icmChannel: 'rijkzwaan-south_africa-Site/rest;loc=en_ZA;cur=ZAR',
    },
    'en_IN': {
        country: 'India',
        icmChannel: 'rijkzwaan-india-Site/rest;loc=en_IN;cur=INR',
    },
    'en_US': {
        country: 'USA',
        icmChannel: 'rijkzwaan-usa-Site/rest;loc=en_US;cur=USD',
    },
    'fr_MA': {
        country: 'Morocco',
        icmChannel: 'rijkzwaan-morocco-Site/rest;loc=fr_MA;cur=MAD',
    },
    'zh_CN': {
        country: 'China',
        icmChannel: 'rijkzwaan-china-Site/rest;loc=zh_CN;cur=CNY',
    },
    'ar_EG': {
        country: 'Egypt',
        icmChannel: 'rijkzwaan-egypt-Site/rest;loc=ar_EG;cur=EGP',
    },
    'vi_VN': {
        country: 'Vietnam',
        icmChannel: 'rijkzwaan-vietnam-Site/rest;loc=vi_VN;cur=VND',
    },
    'en_TZ': {
        country: 'Africa (EN)',
        icmChannel: 'rijkzwaan-africa-Site/rest;loc=en_TZ;cur=TZS',
    },
    'sw_TZ': {
        country: 'Africa (SW)',
        icmChannel: 'rijkzwaan-africa-Site/rest;loc=sw_TZ;cur=TZS',
    },
    'fr_SN': {
        country: 'Africa (FR)',
        icmChannel: 'rijkzwaan-africa-Site/rest;loc=fr_SN;cur=XOF',
    },
    'nl_NL': {
        country: 'Netherlands',
        icmChannel: 'rijkzwaan-netherlands-Site/rest;loc=nl_NL;cur=EUR',
    },
    'ar_JO': {
        country: 'Middle East North Africa',
        icmChannel: 'rijkzwaan-mena-Site/rest;loc=ar_JO;cur=JOD',
    },
    'en_CA': {
        country: 'Canada',
        icmChannel: 'rijkzwaan-canada-Site/rest;loc=en_CA;cur=CAD',
    },
    'ko_KR': {
        country: 'South Korea',
        icmChannel: 'rijkzwaan-south_korea-Site/rest;loc=ko_KR;cur=KRW',
    },
    'es_HN': {
        country: 'Honduras',
        icmChannel: 'rijkzwaan-honduras-Site/rest;loc=es_HN;cur=HNL',
    },
    'es_CR': {
        country: 'Costa Rica',
        icmChannel: 'rijkzwaan-costa_rica-Site/rest;loc=es_CR;cur=CRC',
    },
    'es_PA': {
        country: 'Panama',
        icmChannel: 'rijkzwaan-panama-Site/rest;loc=es_PA;cur=PAB',
    }
};